<template>
  <v-row>
    <v-col>
      <v-tabs
        v-model="tab"
        background-color="transparent"
        color="basil"
      >
        <v-tab
          v-for="item in items"
          :key="item"
        >
          <div class="tab-item">{{ item }}</div>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-container>
            <upload-case></upload-case>
          </v-container>
        </v-tab-item>
        <v-tab-item>
          <v-container>
            <upload-cass-history></upload-cass-history>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import UploadCase from "./upload-case";
import UploadCaseHistory from "./upload-case-history";

export default {
  components: {
    "upload-case": UploadCase,
    "upload-cass-history": UploadCaseHistory,
  },
  data() {
    return {
      tab: null,
      items: ["Upload", "Lot"],
    };
  },
  created() {},
};
</script>