<template>
  <v-row>
    <v-col>
      <v-card-text>
        <ValidationObserver
          ref="observer"
          v-slot="{ valid }"
        >
          <v-row>
            <!-- <v-col
              sm="12"
              md="3"
            >
              <render-input
                :input="fieldConfig.customer"
                v-model="model.customer"
                :disabled="loading"
              ></render-input>
            </v-col> -->
            <v-col
              sm="12"
              md="6"
            >
              <render-input
                :input="fieldConfig.branch"
                v-model="model.branch"
                :disabled="loading"
              ></render-input>
            </v-col>
            <v-col
              sm="12"
              md="3"
            >
              <render-input
                :input="fieldConfig.lotNumber"
                v-model="model.lotNumber"
                :disabled="loading"
              ></render-input>
            </v-col>
            <v-col
              sm="12"
              md="3"
            >
              <render-input
                :input="fieldConfig.lotNumberDesc"
                v-model="model.lotNumberDesc"
                :disabled="loading"
              ></render-input>
            </v-col>
            <v-col
              sm="12"
              md="5"
            >
              <custom-uploadfile
                ref="fileInput"
                @change="handleFile"
                :disabled="!valid || loading"
              ></custom-uploadfile>

            </v-col>
            <v-col>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="blue-grey"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-col>
  </v-row>
</template>

<script>
import { getBranches, getCustomers } from "./../../js/dropdown-service";
import { InputType } from "./../../js/constants";

import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";
import CustomUpLoadFile from "./../../components/custom/custom-uploadfile";
import { getToken } from "../../js/authorization";

export default {
  data() {
    return {
      model: {
        // customer: null,
        branch: null,
        lotNumber: null,
        lotNumberDesc: null,
      },
      loading: false,
    };
  },
  components: {
    "custom-uploadfile": CustomUpLoadFile,
  },
  created() {},
  methods: {
    async handleFile(file) {
      this.$refs.fileInput.clearFile();
      if (!(file && file.length > 0)) {
        return;
      }

      let valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      var data = new FormData();
      data.append("file", file[0]);
      // data.append("customerId", this.model.customer.id);
      data.append("branch", this.model.branch.id);
      data.append("lotNumber", this.model.lotNumber);
      data.append("lotNumberDesc", this.model.lotNumberDesc);

      // console.log(data);

      let url = "/case/uploadFile";
      this.loading = true;
      this.$http
        .uploadFile(url, data)
        .then((res) => {
          this.showAlertSuccess("อัพโหลดข้อมูลสำเร็จ");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    fieldConfig() {
      return {
        // customer: {
        //   name: "customer",
        //   text: "ลูกค้า",
        //   type: InputType.dropdown,
        //   data: {
        //     promise: getCustomers(),
        //   },
        //   rule: {
        //     required: true,
        //   },
        // },
        branch: {
          name: "branch",
          text: "OA",
          type: InputType.dropdown,
          data: {
            promise: getBranches({ authorize: true }),
          },
          rule: {
            required: true,
          },
        },
        lotNumber: {
          name: "lotNumber",
          text: "Lot Date",
          type: InputType.monthPicker,
          rule: {
            required: true,
          },
        },
        lotNumberDesc: {
          name: "lotNumberDesc",
          text: "รายละเอียด",
          type: InputType.text,
          rule: {
            required: true,
            min: 1,
            max: 3,
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.v-card__text {
  height: 300px;
}
</style>