<template>
  <div>
    <!-- search -->
    <master-search
      @results-search="resultChanged"
      :url="configSearch.url"
      :rows="configSearch.rows"
    ></master-search>

    <!-- table -->
    <master-table
      @click-remove="removeData"
      :columns="configResultTable"
      :items="results"
      :showAdd="false"
      :showEdit="false"
      :showRemove="false"
      :permission="permission"
      @exportNotice="exportNotice"
      class="pl-1"
    >
      <template v-slot:table-custom-event-button="{item}">
        <div
          style="margin-top:4px; margin-bottom:4px"
          class="text-left"
        >
          <v-btn
            color="primary"
            v-if="[Customer.AIRA_ID,Customer.AEON_ID,Customer.RABBIT_ID].includes(item.customer.id)"
            small
            @click="openSummaryDateDialog(item)"
          >วันสรุปยอดบัญชี</v-btn>

          <v-btn
            color="primary"
            class="ml-1"
            small
            @click="openJobReceiveDateDialog(item)"
          >กำหนดวันที่รับงาน</v-btn>
        </div>

        <div style="margin-top:4px; margin-bottom:4px">
          <v-btn
            class="mt-3"
            color="primary"
            small
            @click="openKamnodNoticeDateDialog(item)"
          >กำหนดวันที่ Notices</v-btn>
          <v-btn
            class="mt-3 ml-1"
            color="success"
            small
            @click="exportNotice(item)"
          >Export Notices</v-btn>
          <v-btn
            class="mt-3 ml-1"
            color="success"
            small
            @click="exportHistory(item)"
          >Export PGP</v-btn>

          <v-btn
            class="mt-3 ml-1"
            color="success"
            small
            @click="exportNoticeLabel(item)"
          >Export Notice Labels</v-btn>
        </div>

        <div
          style="margin-top:4px; margin-bottom:4px"
          class="text-left"
        >
          <v-btn
            class="mt-3"
            color="success"
            small
            @click="exportBaiNamSongPorNor(item)"
          >Export ใบนำส่ง ปณ.</v-btn>
        </div>

        <div
          style="margin-top:4px; margin-bottom:4px"
          class="text-left"
        >
          <v-btn
            v-if="$store.getters['pageinfo/isSupportMode']"
            class="mt-3"
            color="error"
            small
            @click="deleteLot(item)"
          >ลบ</v-btn>
        </div>
      </template>
    </master-table>

    <custom-dialog
      ref="dateSummaryDialog"
      :width="500"
      title="วันสรุปยอดบัญชี"
      @submit="submitDeptSummaryDate"
    >
      <v-col>
        <render-input
          :input="fieldConfig.deptSummaryDate"
          v-model="deptSummaryDate"
        ></render-input>
      </v-col>
    </custom-dialog>

    <custom-dialog
      ref="jobReceiveDateDialog"
      :width="500"
      title="วันที่รับงาน"
      @submit="submitJobReceiveDate"
    >
      <v-col>
        <render-input
          :input="fieldConfig.jobReceiveDate"
          v-model="jobReceiveDate"
        ></render-input>
      </v-col>
    </custom-dialog>

    <custom-dialog
      ref="kamnodNoticeDateDialog"
      :width="500"
      title="กำหนดวันที่ notices"
      @submit="submitNoticeDate"
    >
      <v-col>
        <render-input
          :input="fieldConfig.noticeDate"
          v-model="noticeDate"
        ></render-input>
      </v-col>
    </custom-dialog>
  </div>
</template>

<script>
import { InputType, Customer } from "./../../js/constants";
import MasterSearch from "./../master-render/master-search";
import MasterTable from "./../master-render/master-table";
import CustomDialog from "./../../components/custom/custom-dialog";
import { momentToThaiFormat } from "./../../js/date-helper";

import { getCustomers } from "./../../js/dropdown-service";
import { fakeAbbr, getFakeUrl } from "./../../_helpers/fake-url";
import moment from "moment";

//prepare Url
let searchUrl = "/upload/History";

export default {
  components: {
    "master-search": MasterSearch,
    "master-table": MasterTable,
    "custom-dialog": CustomDialog,
  },
  data() {
    return {
      results: [],
      currentItem: null,
      deptSummaryDate: null,
      jobReceiveDate: null,
      noticeDate: null,
      Customer,
    };
  },
  methods: {
    exportNotice(item) {
      let url = `/Upload/notices/download?uploadId=${item.id}`;
      this.$http.downloadFile(url);
    },
    exportHistory(item) {
      let url = `/Upload/history/download?lotId=${item.id}`;
      this.$http.downloadFile(url);
    },
    exportBaiNamSongPorNor(item) {
      let url = `/case/lot/${item.id}/noticesPostDoc`;
      this.$http.downloadFile(url);
    },
    deleteLot(item) {
      if (confirm("คุณแน่ใจหรือไม่ที่จะลบรายการนี้ ?")) {
        let url = `/support/DeleteLot/${item.id}`;
        this.$http.delete(url).then((res) => {
          this.showAlertSuccess();
        });
      }
    },
    exportNoticeLabel(item) {
      let url = `/case/lot/${item.id}/noticeLabels`;
      this.$http.downloadFile(url);
    },
    openSummaryDateDialog(item) {
      this.currentItem = item;
      this.deptSummaryDate = item.deptSummaryDate;
      this.$refs.dateSummaryDialog.hideLoading();
      this.$refs.dateSummaryDialog.showDialog();
    },
    openJobReceiveDateDialog(item) {
      this.currentItem = item;
      this.jobReceiveDate = item.jobReceiveDate;
      this.$refs.jobReceiveDateDialog.hideLoading();
      this.$refs.jobReceiveDateDialog.showDialog();
    },
    openKamnodNoticeDateDialog(item) {
      this.currentItem = item;
      this.noticeDate = null;
      this.$refs.kamnodNoticeDateDialog.hideLoading();
      this.$refs.kamnodNoticeDateDialog.showDialog();
    },
    submitDeptSummaryDate() {
      let url = `/case/lot/${this.currentItem.id}/deptSummaryDate`;
      let payload = _.cloneDeep(this.currentItem);
      payload.deptSummaryDate = this.deptSummaryDate;

      this.$http.put(url, payload).then((res) => {
        this.showAlertSuccess();
        this.$refs.dateSummaryDialog.hideDialog();
      });
    },
    submitJobReceiveDate() {
      let url = `/case/lot/${this.currentItem.id}/JobReceiveDate`;
      let payload = _.cloneDeep(this.currentItem);
      payload.jobReceiveDate = this.jobReceiveDate;

      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
        this.$refs.jobReceiveDateDialog.hideDialog();
      });
    },
    submitNoticeDate() {
      let url = `/case/lot/${this.currentItem.id}/noticeDates`;

      let payload = {};
      payload.id = this.currentItem.id;
      payload.noticeDate = this.noticeDate;

      this.$http.post(url, payload).then((res) => {
        this.showAlertSuccess();
        this.$refs.kamnodNoticeDateDialog.hideDialog();
      });
    },
    resultChanged(res) {
      if (Array.isArray(res)) {
        this.results = res;
      } else {
        this.results = [];
      }
    },
    removeData(item, index) {
      if (confirm("คุณแน่ใจหรือไม่ที่จะลบข้อมูลนี้ ?")) {
        this.results.splice(index, 1);
      }
    },
  },
  computed: {
    fieldConfig() {
      return {
        deptSummaryDate: {
          name: "deptSummaryDate",
          text: "วันสรุปยอดบัญชี",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        jobReceiveDate: {
          name: "jobReceiveDate",
          text: "วันที่รับงาน",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        noticeDate: {
          name: "noticeDate",
          text: "วันที่โนติส",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
      };
    },
    configSearch() {
      let url = searchUrl;
      return {
        url: url,
        rows: [
          [
            {
              name: "customer",
              text: "ลูกค้า",
              type: InputType.dropdown,
              data: {
                promise: getCustomers(),
              },
            },
            {
              type: InputType.offset,
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "date",
              text: "     วันที่",
              type: InputType.begin_end_date,
              default: {
                begin: momentToThaiFormat({
                  moment: moment().subtract(1, "months"),
                }),
                end: momentToThaiFormat({ moment: moment() }),
              },
              columnClass: "col-sm-12 col-md-7",
            },
            {
              type: InputType.offset,
            },
          ],
        ],
      };
    },
    configResultTable() {
      return [
        {
          name: "customer.text",
          text: "Customer",
        },
        {
          name: "lotNumber",
          text: "Lot Number",
        },
        {
          name: "uploadedDate",
          text: "Uploaded Date",
        },
        {
          name: "fileName",
          text: "File Name",
        },

        {
          name: "count",
          text: "Count",
        },
      ];
    },
    permission() {
      return {
        remove: ["*"],
      };
    },
  },
};
</script>
